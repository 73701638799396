@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
}

/* *::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #0070ff, #00d2ff, #0070ff);
  border-radius: 10px;
  border: 2px solid #ffff;
} */

.no-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  /* color: black; */
  height: calc(40vh - 98px);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin-top: 50px;
}

.color-blue-50 {
  background-color: #e3f2fd;
}

.color-red-50 {
  background-color: #ffebee;
}

.color-green-50 {
  background-color: #e8f5e9;
}

.color-yellow-50 {
  background-color: #fffde7;
}